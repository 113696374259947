/* eslint-disable react/prop-types */
import i18n from "i18n";
import React from "react";
import { ListItem } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "../../redux/actions/cockpitActions";
import { handleSpecialDisplayDate } from "../../helpers/utilities";
import getVinatisPossibleStates from "../../helpers/clientSpecific/vinatis";
import { getTsOfMessage } from "helpers/messageUtils";

function ActionEventMessageItem(props) {
	const vinatisPossibleStates = getVinatisPossibleStates();
	let message = props.data;

	let state;

	// For moved messages
	let movedInfos = message.payload?.header?.context?.movedInfoTo;

	// From the state of the message, get the good state in translations
	if (message?.header?.state) {
		state = i18n.t(`actionEvent.${message.header.state}`);

		// Specific for update order because we want to display target state
		if (message.header.state.includes("UPDATE_ORDER") && message?.context?.targetState) {
			state += vinatisPossibleStates[message.context.targetState];
		}
	}

	// mID destination for moved messages
	let destination = movedInfos ? movedInfos.to.fID : "";
	let moreStyle = movedInfos ? { padding: 0 } : {};

	// Get the date
	let dateAction = handleSpecialDisplayDate(
		getTsOfMessage(message),
		props.assistantconfig?.cockpit?.datetime_format?.format,
		props.userStatus.auth.user.language.toLowerCase()
	);

	// Get the agent name
	let agentName = message?.agent?.name || message?.agent?.uid || i18n.t("actionEvent.anAgent");
	if (movedInfos) agentName = movedInfos.agentuID;

	let messageText = `${state} ${destination} ${i18n.t("actionEvent.the")} ${dateAction}`;

	const noAgentMessageStates = ["cmPhoneCall_queue"];

	if (!noAgentMessageStates.includes(message?.header?.state) && agentName) {
		messageText += ` ${i18n.t("actionEvent.by")} ${agentName}`;
	}

	return (
		<ListItem style={{ ...moreStyle, justifyContent: "center", fontWeight: "400" }}>
			{messageText}
		</ListItem>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(ActionEventMessageItem);
