/* eslint-disable no-unused-vars */
import C from "../../constants/labels.js";
import store from "../store.js";
import lod_ from "lodash";

import axios from "axios";

// require("dotenv").config();
const endPoint = process.env.REACT_APP_AMBACK;
let previousRequestToken = undefined;

const actions = {
	getLabels: function (assistantID) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let form = {
				email: user.email,
				assistantID: assistantID,
				language: user.language,
				accountID: user.accountID
			};

			return dispatch => {
				//If the previous request still executing ==> cancel it
				if (previousRequestToken) {
					previousRequestToken.cancel();
				}

				///Reset labels for current assistant
				dispatch({
					type: C.RESET_LABELS,
					payload: { assistantID: assistantID }
				});
				const source = axios.CancelToken.source();
				previousRequestToken = source;

				axios(endPoint + "/api/labels", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: form,
					cancelToken: source.token
				})
					.then(rep => {
						previousRequestToken = undefined;

						if (rep.data && Array.isArray(rep.data)) {
							let payload = {
								labels: rep.data,
								assistantID: assistantID
							};
							dispatch({
								type: C.GET_LABELS,
								payload: payload
							});
						}
					})
					.catch(error => {
						previousRequestToken = undefined;

						// if (axios.isCancel(error)) {
						// 	console.warn(`Cancel previous getting labels request`);
						// } else {
						// 	console.error(error);
						// }
					});
			};
		}
	}
};
export default actions;
