import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { Box, Popover, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import cockpitActions from "../../redux/actions/cockpitActions";
import DisplayItem from "./DisplayItem";
import { loadSuggestionsEnrich } from "./business/searchDataInCrmCommons";
import i18n from "i18n";
import { errorMsg } from "redux/reducers/snackMsgsReducers";

const SearchDataEnrichInput = ({ anchorEl, handleClose, handleSubmit, actionObject }) => {
	const [searchField, setSearchField] = useState("");
	const [suggestions, setSuggestions] = useState([]);

	const dispatch = useDispatch();

	const host = actionObject.config.host;
	const shownFields = actionObject.config.shownFields;
	const sourceApi = actionObject?.config?.sourceApi;
	const searchPath = actionObject?.config?.searchPath;

	const { assistantID, user, selectedConversation } = useSelector(state => {
		return {
			assistantID: state.assistantconfig.assistantID,
			user: state.userStatus.auth.user,
			selectedConversation: state.cockpit.selectedConversation
		};
	});

	async function handleAutocompleteChange(event, newInputValue, reason) {
		// This componenent manages its action by itself, but still call handleSubmit to close actions tabs

		if (newInputValue && reason === "select-option") {
			let url = `${host}/api/v1/${searchPath}/info`; // TODO add sort
			try {
				const resCall = await axios.post(
					url,
					{
						account: newInputValue,
						sourceApi,
						context: selectedConversation?.context
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `${user.token}`,
							UserEmail: user.email,
							assistantID
						}
					}
				);
				if (resCall?.response?.data?.error) {
					dispatch(errorMsg(`${resCall?.response?.data?.error?.message}`));
				} else {
					let data = {
						header: {
							assistantID: selectedConversation?.header?.assistantID,
							fID: selectedConversation?.header?.fID
						},
						payload: {}
					};
					if (resCall?.data?.results) {
						data.payload = resCall?.data?.results;
					}
					dispatch(cockpitActions.launchActionFrontV2("updateConvDictionaryPartial", data));
				}

				handleClose();
				handleSubmit({}, actionObject.action);
			} catch (err) {
				handleClose();
				handleSubmit({}, actionObject.action);
			}
		}
	}

	function handlInputChange(event) {
		setSearchField(event.target.value);
	}

	useEffect(() => {
		if (searchField) {
			const timeoutId = setTimeout(async () => {
				const suggestionsFetched = await loadSuggestionsEnrich(
					assistantID,
					user,
					searchField,
					host,
					sourceApi,
					selectedConversation?.context,

					searchPath
				);
				setSuggestions(suggestionsFetched);
			}, 500);
			return () => clearTimeout(timeoutId);
		}
	}, [searchField]);

	return (
		<>
			<Popover
				open={true}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right"
				}}
			>
				<Box display="flex" flexDirection="row" alignItems="center" padding="10px">
					<Autocomplete
						id="autocomplete-recipient"
						freeSolo
						options={suggestions}
						style={{ minWidth: "300px" }}
						getOptionLabel={option => option.name}
						onChange={handleAutocompleteChange}
						filterOptions={(options, state) => options}
						renderOption={(suggestedValue, option) => DisplayItem(suggestedValue, shownFields)}
						renderInput={params => (
							<TextField
								{...params}
								label={actionObject.config.searchLabel || "Rechercher"}
								variant="outlined"
								size="medium"
								autoFocus={true}
								value={searchField}
								onChange={handlInputChange}
							/>
						)}
					/>
				</Box>
			</Popover>
		</>
	);
};

export default SearchDataEnrichInput;
