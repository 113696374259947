/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unknown-property */
import { Box, Grid, Paper } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import GetChannelImage from "helpers/GetChannelImage";
import i18n from "i18n";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getMesssageItemSelector } from "redux/selectors/cockpitSelectors";
import GetAppIcon from "@material-ui/icons/GetApp";
import "../../Message/MessageItem.css";
import { downloadColdFile } from "helpers/utilities";

const MessageItemCOLD = ({ convHistory }) => {
	const { channels, selectedConversation } = useSelector(getMesssageItemSelector, shallowEqual);
	const [pdfLink, setPdfLink] = useState();
	const conversation = convHistory ? convHistory : selectedConversation;

	/**
	 * To insert the PDF
	 * Amelioration: stock it in the conv inside the conversations array to not re download it each tile we click on it
	 */
	useEffect(() => {
		downloadColdFile(conversation.payload?.PDFLink, true).then(res => setPdfLink(res));
	}, [selectedConversation]);

	return (
		<Paper elevation={3} style={{ margin: "2%" }}>
			<React.Fragment>
				<Box fontWeight="fontWeightBold" p={1} display="flex" alignItems="center">
					<Box flexGrow={1}>
						<Grid container alignItems="center">
							<Box component="span" mr={0.5}>
								<img
									src={GetChannelImage(
										channels,
										conversation.meta?.cockpit?.channelCode || "",
										selectedConversation.header.channel
									)}
									alt="Channel-logo"
									width="20"
									height="20"
									className="logo-channel"
								/>
							</Box>
						</Grid>
					</Box>
					{
						<Box>
							<ToggleButtonGroup style={{ paddingLeft: 8 }} size="small" exclusive>
								<ToggleButton size="small" value={true}>
									<a href={pdfLink} target="_blank" rel="noreferrer" download>
										<GetAppIcon />
									</a>
								</ToggleButton>
							</ToggleButtonGroup>
						</Box>
					}
				</Box>

				{conversation.payload?.attachments.length !== 0 && (
					<Box
						style={{ display: "flex", flexDirection: "column", flexWrap: "flexWrap" }}
						fontWeight="fontWeightMedium"
						mx={1}
						mb={0.5}
					>
						<p> {i18n.t("COC.Attachements")} :</p>
						{conversation.payload?.attachments.map((e, i) => {
							return (
								e.name &&
								e.url && (
									<a
										key={`${e.name}_${i}`}
										onClick={() => downloadColdFile(e.url)}
										style={{ marginLeft: "10px", fontStyle: "italic", cursor: "pointer" }}
									>
										{e.name}
										{conversation.payload?.attachments?.length > 1 && ","}
									</a>
								)
							);
						})}
					</Box>
				)}
				<Box>
					{pdfLink && (
						<iframe src={pdfLink} width="100%" style={{ height: "75vh" }}>
							{" "}
						</iframe>
					)}
				</Box>
			</React.Fragment>
		</Paper>
	);
};

export default MessageItemCOLD;
