/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable eqeqeq */
/* eslint consistent-return: 2*/
/* eslint-disable no-mixed-operators */
import { getACleanURI } from "helpers/utilities.js";
import axios from "axios";
import i18n from "i18next";
import lod_ from "lodash";
import moment from "moment";
import { infoMsg } from "redux/reducers/snackMsgsReducers.js";
import { errorMsg } from "redux/reducers/snackMsgsReducers.js";
import C from "../../constants/cockpit.js";
import C_engage from "../../constants/engage";

import store from "../store.js";
import { assistantHasApiCold, changeDeep, isConversationLive } from "./../../helpers/utilities";
import { api } from "./api.js";
import { wsEvent } from "./ws";
import { setSendEnabled, setSendSucceed } from "redux/reducers/conversationReducers";
import { getMustForceConversationSelection } from "../../helpers/actions/events";
import generalConstant from "../../constants/general";
import { addConversationHiddenFields } from "redux/reducers/conversationReducers.js";
import { computeAgentName } from "helpers/conversationUtils.js";

// require("dotenv").config();

const endPoint = process.env.REACT_APP_AMBACK;

const actions = {
	getConversationsCountByQuery: function (assistantID, cold) {
		//TODO: with filters (for default filters)
		let storeState = store.getState();
		let user = storeState.userStatus.auth.user;
		let role = user.role;
		let groups = user.groups;
		const onSuccess = res => ({
			type: C.SET_CONVS_NUMBERS,
			payload: {
				...res
			}
		});
		const onSuccessCold = res => {
			return {
				type: C.SET_COLD_CONVS_NUMBER,
				payload: res.totalCount
			};
		};
		return dispatch => {
			if (assistantHasApiCold() && cold) {
				dispatch(
					api({
						type: "cockpit/getColdConversationsCountByQuery",
						url: "/api/v1/cold/conversations/search",
						data: {
							page: 1,
							perPage: 1,
							assistantID: storeState.selectedAssistantID
						},
						onSuccess: onSuccessCold,
						onFailure: "cold"
					})
				);
			} else if (!cold)
				dispatch(
					api({
						type: "cockpit/getConversationsCountByQuery",
						url: `/api/v1/conversationsCount/${assistantID}`,
						method: "POST",
						data: {
							role: role,
							groups: groups
						},
						onSuccess
					})
				);
		};
	},

	getScrollPaginatedColdConversations: function (limit, skip) {
		let storeState = store.getState();
		const leftTab = storeState.cockpit.leftTab;
		const cockpit = store.getState().cockpit;

		const page = 1 + Math.round(skip / limit);

		const onSuccess = res => {
			const actionsToSend = [
				{
					type: C.SET_COLD_CONVS_NUMBER,
					payload: res.totalCount
				}
			];
			if (leftTab === store.getState().cockpit.leftTab) {
				//if we  didn't change tab before getting the request result
				if (page === 1) {
					actionsToSend.push({
						type: C.UPDATE_CONVERSATIONS,
						payload: res.conversations
					});
					actionsToSend.push({
						type: C.SET_CURRENT_CONVS_NUMBERS,
						payload: ["cold", res.totalCount]
					});
				} else
					actionsToSend.push({
						type: C.ADD_CONVERSATIONS,
						payload: { res: res.conversations }
					});
			}
			return actionsToSend;
		};
		const before = cockpit.convStartDateFilter && new Date(cockpit.convStartDateFilter);
		const after = cockpit.convEndDateFilter && new Date(cockpit.convEndDateFilter);

		return async dispatch => {
			dispatch(
				api({
					type: "cockpit/getScrollPaginatedColdConversations",
					url: "/api/v1/cold/conversations/search",
					data: {
						page,
						perPage: limit,
						search: cockpit.convSearchFilter,
						before: before ? moment(before).format("YYYY-MM-DD") : null,
						after: after ? moment(after).format("YYYY-MM-DD") : null,
						assistantID: store.getState().selectedAssistantID
					},
					onSuccess,
					onFailure: "cold"
				})
			);
		};
	},

	/**
	 *
	 * @param {*} assistantID
	 * @param {*} type
	 * @param {*} limit
	 * @param {*} skip
	 * @param {*} count
	 * @returns
	 */
	getScrollPaginatedConversations: function (type, limit, skip, count) {
		let storeState = store.getState();
		let currentAssistantID = storeState.selectedAssistantID;
		const leftTab = storeState.cockpit.leftTab;
		const {
			convSearchFilter,
			convChannelFilter,
			convLanguageFilter,
			convPriorityFilter,
			convSentimentFilter,
			convEscalationFilter,
			convSubstateFilter,
			convConnectedFilter,
			convIntentFilter,
			convAscFilter,
			convStartDateFilter,
			convEndDateFilter,
			convGroupFilter,
			convUserFilter,
			convAgentFilter,
			convSupervisorFilter,
			isCockpitArchived,
			convDirectionFilter,
			convPathContextFilter,
			convNoBotFilter
		} = storeState.cockpit;
		const userEmail = storeState.userStatus?.auth?.user?.email;
		const userRole = storeState.userStatus?.auth?.user?.role;

		const filters = {
			search: convSearchFilter,
			channel: convChannelFilter?.code?.toUpperCase(),
			language: convLanguageFilter,
			priority: convPriorityFilter,
			sentiment: convSentimentFilter,
			escalation: convEscalationFilter,
			subState: leftTab !== 0 && convSubstateFilter,
			connected: leftTab === 3 ? convConnectedFilter : null, // do not send connected if leftab not Live (tab===3)
			intent: convIntentFilter,
			asc: typeof convAscFilter !== "boolean" ? true : convAscFilter,
			startDate: convStartDateFilter,
			endDate: convEndDateFilter,
			groups: convGroupFilter.map(group => group._id),
			noBot: lod_.isNil(convNoBotFilter) ? false : convNoBotFilter,
			direction: lod_.isNil(convDirectionFilter) ? "" : convDirectionFilter,
			pathContext:
				lod_.isNil(convPathContextFilter) || lod_.isEmpty(convPathContextFilter)
					? {}
					: convPathContextFilter,
			users: leftTab === 0 ? (isCockpitArchived ? convUserFilter : []) : convUserFilter,
			// do not send agent for waiting (==0) nor for Live (==3)
			agent:
				convAgentFilter &&
				(leftTab !== 0 || isCockpitArchived) &&
				leftTab !== 3 &&
				userRole === "agent"
					? userEmail || ""
					: null,
			supervisor:
				convSupervisorFilter &&
				(leftTab !== 0 || isCockpitArchived) &&
				leftTab !== 3 &&
				(userRole === "supervisor" || userRole === "soloUser")
					? userEmail
					: null
		};

		const onSuccess = res => {
			let actionsToSend = [];
			if (res && leftTab === store.getState().cockpit.leftTab) {
				//if we  didn't change
				if (skip === 0) {
					// if  first  page
					actionsToSend = [
						{
							type: C.UPDATE_CONVERSATIONS,
							payload: res.conversations
						}
					];
				} else {
					actionsToSend = [
						{
							type: C.ADD_CONVERSATIONS,
							payload: {
								res: [...res.conversations],
								state: type,
								skip,
								limit
							}
						}
					];
				}
				if (res.count && res.count[0]) {
					actionsToSend.push({
						type: C.SET_CURRENT_CONVS_NUMBERS,
						payload: res.count
					});
					actionsToSend.push({
						type: C.SET_CONVS_NUMBERS,
						payload: res.totalCount
					});
				}

				return actionsToSend;
			}
		};
		if (lod_.has(storeState, "userStatus.auth.user") && lod_.has(storeState, "assistantconfig")) {
			return async dispatch => {
				// Set the store variable "cockpit.conversationsFetched" to false
				// This variable is used to know if the conversations have been fetched or not
				// used in reducer UPDATE_CONVERSATION_EVENT
				dispatch({
					type: C.START_TO_FETCH_CONVS,
					payload: {}
				});

				let sort = {
					// "header.humanRequest": -1,
					"meta.cockpit.ts": filters.asc ? -1 : 1,
					"header.start": filters.asc ? -1 : 1
				};
				let storeState = store.getState();
				let user = storeState.userStatus.auth.user;
				let role = user.role;
				let groups = user.groups;
				// console.log(
				// 	"PIKA -> getScrollPaginatedConversations"
				// 	// JSON.stringify({
				// 	// 	sort,
				// 	// 	limit,
				// 	// 	skip,
				// 	// 	filters,
				// 	// 	count
				// 	// })
				// );
				await dispatch(
					api({
						method: "POST",
						type: "cockpit/getScrollPaginatedConversations",
						url: `/api/v1/conversations/${currentAssistantID}/${type}`,
						data: {
							groups,
							role,
							sort,
							limit,
							skip,
							filters,
							count
						},
						onSuccess
					})
				);
			};
		} else {
			return dispatch => {
				dispatch({
					type: C.ADD_CONVERSATIONS,
					payload: {
						res: [],
						state: type
					}
				});
			};
		}
	},

	/**
	 *
	 * @param {*} fID
	 * @param {*} isArchived
	 * @param {*} language
	 * @param {*} action
	 * @param {*} rcvAttAll
	 * @param {*} sntAttAll
	 * @param {*} extraData
	 * @param {*} expectFile
	 * @param {*} queryParams
	 * @param {*} actionDialog
	 * @param {*} successCB
	 * @param {*} failCB
	 * @param {*} conversation
	 * @returns
	 */
	doAnAction: function (
		fID,
		isArchived,
		language,
		action,
		rcvAttAll,
		sntAttAll,
		extraData,
		expectFile,
		queryParams,
		actionDialog,
		successCB,
		failCB,
		conversation
	) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let token = store.getState().userStatus.auth.user.token;
			let agentSender = {
				name: `${user.name || ""} ${user.given_name || ""}`,
				uid: user.email
			};

			let currentAssistantID = store.getState().selectedAssistantID;

			if (queryParams?.channel) {
				let selectedChannel = store
					.getState()
					.assistantconfig.channels.find(channel => channel.type === queryParams.channel);

				let currentCM_FRU = currentAssistantID + "_" + selectedChannel.code;
				let currentMQN = selectedChannel.mqn; // for exotic client we need where to send the message
				let currentClient = selectedChannel.client;

				queryParams.moreInfos = {
					currentCM_FRU: currentCM_FRU,
					currentMQN: currentMQN,
					currentClient: currentClient
				};
			}

			var attachments = [];

			try {
				let indexMsg = conversation.messages.length - 1;
				let iAmForwardAttachements = conversation.messages[indexMsg].body?.attachments?.filter(
					e => e.contentDisposition !== "inline"
				);
				attachments = conversation.messages[indexMsg].sent_attachments
					? conversation.messages[indexMsg].sent_attachments
					: iAmForwardAttachements;
			} catch {
				attachments = [];
			}

			let mailTransition = {
				limiter: i18n.t("EDIT_FORM.forward_line"),
				topic: i18n.t("EDIT_FORM.metaTopic"),
				from: i18n.t("COC.from")
			};

			let contactName = "";
			if (conversation?.meta?.cockpit?.contact) {
				contactName = conversation?.meta?.cockpit?.contact
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "");
			} else if (conversation?.contact?.email) {
				contactName = conversation?.contact?.email;
			} else if (conversation?.contact?.phone) {
				contactName = conversation?.contact?.phone;
			}

			let data = {
				assistantID: currentAssistantID,
				agentSender,
				fID,
				isArchived,
				language,
				action,
				token,
				rcvAttAll,
				sntAttAll,
				extraData,
				queryParams,
				mailTransition,
				attachments,
				contactName
			};

			return dispatch => {
				let options = {
					url: endPoint + "/api/v1/callTabAction",
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
						// Accept: 'application/pdf',
					},
					method: "POST",
					data: data
				};

				if (expectFile) {
					options.responseType = "arraybuffer";
				}

				axios(options)
					.then(res => {
						if (res.status == 200) {
							if (expectFile) {
								const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
								const link = document.createElement("a");
								link.href = downloadUrl;
								link.setAttribute("download", res.headers["content-language"]);

								document.body.appendChild(link);
								link.click();
								link.remove();
							}

							// START -> OLD CODE
							let payload = {
								res: res.data
							};
							dispatch({
								type: C.DOWNLOAD_ZIP,
								payload: payload
							});
							if (successCB) {
								successCB(expectFile);
							}
							// END -> OLD CODE"";;
							if (
								action === "downloadConversationPDFWithAttachementsToClipboard" ||
								action === "downloadConversationPDFToClipboard"
							) {
								dispatch(infoMsg("Element(s) ajouté(s)"));
							} else {
								dispatch(infoMsg("Action effectuée"));
							}
						}
					})
					.catch(err => {
						dispatch(errorMsg("Problème d'ajout de l'élément"));

						if (err && err.response && err.response.data) {
							let error;

							if (expectFile) {
								error = JSON.parse(new TextDecoder("utf-8").decode(err.response.data));
							} else {
								error = err.response.data;
							}
							let errorCode = error.errorCode;

							if (failCB) {
								failCB(errorCode, actionDialog);
							}
						} else {
							if (failCB) {
								failCB("UNKNOWN_ERROR", actionDialog);
							}
						}
					});
			};
		}
	},

	/**
	 *
	 * @param {*} arrUrl
	 * @param {*} successCB
	 * @param {*} failCB
	 * @returns
	 */
	getAllReceiveAttachments: function (arrUrl, successCB, failCB) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let currentFID = store.getState().cockpit.selectedConversation.header.fID;
			let assistantID = store.getState().cockpit.selectedConversation.header.assistantID;
			let data = { assistantID, arrUrl };

			return dispatch => {
				// PIKA: Information aussi utile que la météo. On va essayer de basculer tous les endpoints sur api/v1 au fur et à meusure
				axios(endPoint + "/api/v1/downloadAttachments", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data,
					responseType: "arraybuffer" // PIKA: fait partie de la bouse citée plus basddd
				})
					.then(async res => {
						if (res.status == 200) {
							// PIKA: Il y a le zip et que le zip dans res.data (sauf si on met le blob), je pense qu'il faudrait prévoir de faire un download normal

							// J'ai trouvé une espèce de bouse que je mets ici : https://dev.to/doxomo/how-to-download-files-with-axios-2b8g

							const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
							const link = document.createElement("a");
							link.href = downloadUrl;
							link.setAttribute("download", `${currentFID}.zip`); //any other extension

							document.body.appendChild(link);
							link.click();
							link.remove();

							// START -> OLD CODE
							let payload = {
								res: res.data
							};
							dispatch({
								type: C.DOWNLOAD_ZIP,
								payload: payload
							});
							if (successCB) {
								successCB();
							}
							// END -> OLD CODE
						} else {
							if (failCB) {
								failCB();
							}
						}
					})
					.catch(err => {
						if (failCB) {
							failCB();
						}
					});
			};
		}
	},

	/**
	 * Save the draft of a conversation, with fID and possibles values to update
	 * @param {string} fID fabrik conversation unique identifier
	 * @param {string} assistantID
	 * @param {object} newDraftValues new values to update the draft, can be text, topic etc..
	 */
	postDraft: function (fID, assistantID, substate, newDraftValues) {
		const data = {
			fID,
			assistantID,
			substate,
			...newDraftValues
		};

		const onLoading = payload => ({
			type: C.POST_DRAFT_LOADING,
			payload
		});

		const onSuccess = () => {
			return {
				type: C.POST_DRAFT,
				payload: data
			};
		};

		return dispatch =>
			dispatch(
				api({
					type: "cockpit/postDraft",
					onLoading,
					url: "/api/v1/postDraft",
					method: "POST",
					data,
					onSuccess
					// onFailure: failCB
					// TODO
					// setSnackbarStatusDraft({ open: true, message: props.t("COC.DraftFailed"), severity: "error" });
				})
			);
	},

	/**
	 *
	 * @param {*} conv
	 * @returns
	 */
	getHistoryCount: function (conv) {
		let user = store.getState().userStatus.auth.user;
		return dispatch => {
			if (conv?.contact?.FRU) {
				axios(endPoint + "/api/v1/cockpit/fetchHistory", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: {
						FRU: conv.contact.FRU,
						fID: conv.header.fID,
						assistantID: conv.header.assistantID,
						type: "count"
					}
				})
					.then(result => {
						if (result.status == 200) {
							let payload = result.data;
							dispatch({
								type: C.COUNT_HISTORY,
								payload: payload
							});
						} else {
							dispatch({
								type: C.COUNT_HISTORY,
								payload: {
									totalHistoryCount: 0,
									isHistoryConvOngoingOrWaiting: false
								}
							});
						}
					})
					.catch(error => {
						dispatch({
							//If we cannot fetch history count for whatever reason, we reset the count
							type: C.COUNT_HISTORY,
							payload: {
								totalHistoryCount: 0,
								isHistoryConvOngoingOrWaiting: false
							}
						});
					});
			} else {
				dispatch({
					type: C.COUNT_HISTORY,
					payload: {
						totalHistoryCount: 0
					}
				});
			}
		};
	},
	/**
	 *
	 * @param {*} conv
	 * @returns
	 */
	FetchHistory: function (
		conv,
		type = null,
		onSuccess = payload => {
			return {
				type: C.UPDATE_HISTORY,
				payload: payload
			};
		},
		onFailure = error => {},
		onLoading = payload => ({
			type: C.HISTORY_LOADING,
			payload
		})
	) {
		let data = {
			FRU: conv.contact.FRU,
			fID: conv.header.fID,
			assistantID: conv.header.assistantID,
			type: type
		};
		return dispatch => {
			dispatch(
				api({
					type: "cockpit/fetchHistory",
					onLoading,
					url: "/api/v1/cockpit/fetchHistory",
					method: "POST",
					data,
					onSuccess,
					onFailure
				})
			);
		};
	},

	/**
	 * Update the store when an ws event arrive with type of new_conversation
	 * @param {object} event the websocket event
	 */
	newConversationEvent: function async(event) {
		let currentAssistantID = store.getState().selectedAssistantID;

		if (currentAssistantID === event.assistantID) {
			const mustForceConversationSelection = getMustForceConversationSelection(event);

			// Classic new_conversation
			return dispatch => {
				let actionsToDispatch = [
					{
						type: C.ADD_CONVERSATION_EVENT,
						payload: {
							event,
							currentAssistantID,
							user: store.getState().userStatus.auth.user
						}
					}
				];
				if (mustForceConversationSelection) {
					// new_conversation + we want to select the conversation
					actionsToDispatch.push(this.forceConversationSelection(event, 1)); //1: ongoing
				}
				return dispatch(actionsToDispatch);
			};
		}
	},

	/**
	 *
	 * @param {*} message
	 * @returns
	 */
	newMessageEvent: function (message) {
		let storeState = store.getState();
		let currentAssistantID = storeState.selectedAssistantID;
		let assistantID = message.assistantID;
		if (currentAssistantID === assistantID) {
			if (
				lod_.has(storeState, "cockpit.selectedConversation._id") &&
				storeState.cockpit.selectedConversation?.header?.fID === message.fID
			) {
				// Update the store only if the conversation is selected
				return dispatch => {
					dispatch({
						type: C.ADD_MESSAGE_EVENT,
						payload: message
					});
				};
			}
		}
	},

	/**
	 *
	 * @param {*} event
	 * @returns
	 */
	updateConversationEvent: function (event) {
		let currentAssistantID = store.getState().selectedAssistantID;
		let assistantID = event.assistantID;
		if (currentAssistantID === assistantID) {
			return dispatch => {
				let actionsToDispatch = [
					{
						type: C.UPDATE_CONVERSATION_EVENT,
						subtype: event.subtype,
						payload: { content: event.content, user: store.getState().userStatus.auth.user }
					}
				];

				if (
					event.content?.header?.substate === "pickup" &&
					event.content?.agent?.uid === store.getState()?.userStatus?.auth?.user?.email
				) {
					actionsToDispatch.push(infoMsg(i18n.t("COC.selectedCallConversation")));
					actionsToDispatch.push(this.forceConversationSelection(event, 3)); //3:Live
				}

				dispatch(actionsToDispatch);
			};
		}
	},
	/**
	 * Update the notes for a conversation
	 * @param {*} event
	 * @returns
	 */
	updateConversationNotesEvent: function (event) {
		let currentAssistantID = store.getState().selectedAssistantID;
		let assistantID = event.assistantID;
		if (currentAssistantID === assistantID) {
			return dispatch => {
				let actionsToDispatch = [
					{
						type: C.UPDATE_CONVERSATION_NOTES_EVENT,
						subtype: event.subtype,
						payload: { content: event.content, user: store.getState().userStatus.auth.user }
					}
				];
				dispatch(actionsToDispatch);
			};
		}
	},
	/**
	 * Called when 	a message has been translated
	 */
	updateConversationWithTranslation: function (event) {
		let currentAssistantID = store.getState().selectedAssistantID;
		let assistantID = event.assistantID;
		if (currentAssistantID === assistantID) {
			return dispatch => {
				let actionsToDispatch = [
					{
						type: C.UPDATE_CONVERSATION_EVENT,
						payload: { content: event.content, user: store.getState().userStatus.auth.user }
					}
				];

				actionsToDispatch.push(infoMsg(i18n.t("actionEvent.translationDone")));

				dispatch(actionsToDispatch);
			};
		}
	},

	forceConversationSelection: function (event, tab) {
		return dispatch => {
			let actionsToDispatch = [];
			actionsToDispatch.push(this.selectConversation(event.content));
			actionsToDispatch.push({ type: C.SET_IS_COCKPIT_ARCHIVED, payload: false });
			actionsToDispatch.push({ type: C.SET_FORCE_SELECT_CONVERSATION, payload: true });
			actionsToDispatch.push({ type: C.SET_LEFT_TAB, payload: tab }); //Go to Live tab
			dispatch(actionsToDispatch);
		};
	},

	/**
	 *
	 * @param {*} state
	 * @param {*} substate
	 * @param {*} conv
	 * @returns
	 */
	updateConversationState: function (state, substate, conv, success = null) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let isArchived = store.getState().cockpit.leftTab === 3;

			let agentName =
				user && user.surname ? user.surname + " " + user.name : user.nickname + " " + user.name;

			let ts = Date.now();

			let hours = new Date(ts).toLocaleTimeString("fr-FR");
			let CompleteDay = new Date(ts).toLocaleDateString("fr-FR");
			let day = CompleteDay.substring(0, 2);
			let month = CompleteDay.substring(3, 5);
			let year = CompleteDay.substring(6, 10);

			let data = {
				action: "updateConvState",
				data: {
					header: {
						fID: conv.header.fID,
						assistantID: conv.header.assistantID
					},
					payload: {
						state: state,
						substate: substate
					}
				},
				source: generalConstant.APP_NAME,
				ts
			};

			return dispatch =>
				dispatch(
					api({
						type: "cockpit/updateConversationState",
						url: "/api/v1/actionFront",
						method: "POST",
						data,
						onSuccess: res => {
							if (success) success(res);
						},
						onFailure: e => {}
					})
				);
		}
	},

	/**
	 *
	 * @param {*} conv
	 * @param {*} success
	 * @param {*} fail
	 * @returns
	 */
	assignConversation: function (conv, success, fail) {
		const onSuccess = () => {
			if (success) success();

			let storeState = store.getState();
			let switchTabOnAssignation = true;
			const isLive = isConversationLive(conv.header);
			if (
				lod_.has(storeState, "assistantconfig.cockpit.switchTabOnAssignation") &&
				!storeState.assistantconfig.cockpit.switchTabOnAssignation
			) {
				// Made temporarly to avoid to reload all waiting conversation
				switchTabOnAssignation = false;
			}

			const user = storeState.userStatus.auth.user;
			const nickname =
				user && user.surname ? user.surname + " " + user.name : user.nickname + " " + user.name;

			// Main dispatch to assignConversation
			let mainDispatch = {
				type: C.ASSIGN_CONVERSATION,
				payload: {
					conv,
					nickname,
					email: store.getState().userStatus.auth.user.email,
					switchTabOnAssignation
				}
			};

			let dispatchList = switchTabOnAssignation
				? [
						// Main case
						{
							type: C.SET_LEFT_TAB,
							payload: isLive ? 3 : 1
						},
						mainDispatch,
						{
							type: C.EMPTY_CONV_STATE,
							value: "waiting"
						},
						this.selectConversation(conv)
					]
				: [mainDispatch];
			if (isLive) {
				dispatchList.push(this.selectConversation(conv));
			}

			return dispatchList;
		};

		const onFailure = error => {
			if (error?.response?.status === 403) {
				if (fail) fail("client");
				if (error.response.data && error.response.data.conv) {
					return {
						type: C.UPDATE_CONVERSATION_EVENT,
						payload: {
							content: error.response.data.conv,
							user: store.getState().userStatus.auth.user
						}
					};
				} else {
					//Else, simply remove the conversation from conversations list
					return {
						type: C.DELETE_CONVERSATION,
						payload: conv.header.fID
					};
				}
			} else {
				if (fail) fail("server");
				return {
					type: ""
				};
			}
		};

		return this.updateConversationAssignment(conv, "assign", onSuccess, onFailure);
	},
	updateConversationAssignment: function (conv, action, onSuccess, onFailure) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let data = {
				data: {
					header: {
						assistantID: conv.header.assistantID,
						fID: conv.header.fID
					},
					payload: {}
				},
				source: generalConstant.APP_NAME,
				ts: Date.now()
			};

			if (action != "reject") {
				data.action = "assignConv";
				data.data.payload = {
					to: {
						name: computeAgentName(user),
						first: user.name,
						last: user.surname,
						email: user._id
					}
				};
			} else {
				data.action = "rejectConv";
			}

			return dispatch => {
				dispatch({ type: C.CLEAR_CONVERSATION });
				dispatch(
					api({
						type: "cockpit/updateConversationAssignment",
						url: "/api/v1/actionFront",
						method: "POST",
						data,
						onSuccess,
						onFailure
					})
				);
			};
		}
	},
	/**
	 *
	 * @param {*} contact
	 * @param {*} assistantID
	 * @returns
	 */
	updateContact: function (contact, assistantID, dictionary) {
		const onSuccess = data => ({
			type: C.UPDATE_CONTACT,
			payload: data
		});
		return dispatch => {
			const contactID = contact.FRU;
			dispatch(
				api({
					method: "PUT",
					type: "cockpit/:assistantID/contact/:FRU",
					url: `/api/v1/${assistantID}/contact/${contactID}`,
					data: { contact, dictionary },
					onSuccess
				})
			);
		};
	},

	/**
	 *
	 * @param {*} contact
	 * @param {*} assistantID
	 * @returns
	 */
	updateContactToCRM: function (contact, assistantID) {
		return dispatch => {
			const contactID = contact.FRU;
			dispatch(
				api({
					method: "PUT",
					type: "cockpit/:assistantID/contact/:FRU",
					url: `/api/v1/${assistantID}/contact/CRM/${contactID}`,
					data: contact
				})
			);
		};
	},

	/**
	 *
	 * @param {*} contact the conversation's contact object
	 * @param {*} header the conversation fID
	 * @returns
	 */
	updateConversationContact: function (contact, header) {
		let id = header.fID;
		let assistantID = header.assistantID;
		const onSuccess = data => [
			{
				type: C.UPDATE_CONV_CONTACT,
				payload: {
					data: {
						contact,
						id,
						computedContact: data.computedContact
					}
				}
			},
			infoMsg("Sauvegardé")
		];
		const onFailure = err => {
			return [
				{
					type: C.UPDATE_CONV_CONTACT,
					payload: {
						data: {
							contact: { ...store.getState().cockpit.selectedConversation?.contact, reload: true },
							id: store.getState().cockpit.selectedConversation?.header?.fID
						}
					}
				},
				errorMsg("erreur")
			];
		};
		let data = {
			action: "updateConvContact",
			data: {
				header: {
					assistantID,
					fID: header.fID
				},
				payload: {
					contact
				}
			},
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};
		return async dispatch => {
			dispatch(
				api({
					method: "POST",
					type: "cockpit/conversation/:id/contact",
					url: `/api/v1/actionFront`,
					data,
					onSuccess,
					onFailure
				})
			);
		};
	},
	/**
	 *
	 * @param {*} context the conversation's context object
	 * @param {*} id the conversation fID
	 * @returns
	 */
	updateContext: function (context, header) {
		let data = {
			action: "updateConvContext",
			data: {
				header: {
					assistantID: header.assistantID,
					fID: header.fID
				},
				payload: {
					context
				}
			},
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};

		// const onSuccess = () => [
		// 	{
		// 		type: C.UPDATE_CONTEXT,
		// 		payload: data
		// 	}
		// ];

		return async dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/actionFront`,
					data
					//onSuccess
				})
			);
		};
	},

	/**
	 *
	 * DO action for gestion category of message (changeCategorySatusMessage, deleteCategoryMessage and addCategoryMessage)
	 * @param {*} action the action will do NYO
	 * @param {*} category info of category to do depending on the action
	 * @param {*} header the conversation header
	 * @returns
	 */
	gestionCategoryMessage: function (action, params, header) {
		let data = {
			action,
			data: {
				header: {
					assistantID: header.assistantID,
					fID: header.fID
				},
				payload: {
					...params
				}
			},
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};

		return async dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/actionFront`,
					data
					//onSuccess
				})
			);
		};
	},

	/**
	 *
	 * @param {*} conv
	 * @returns
	 */
	rejectConversation: function (conv) {
		const onSuccess = () => {
			return [
				{
					type: C.REJECT_CONVERSATION,
					payload: {
						conv
					}
				},
				infoMsg(i18n.t("COC.Reject"))
			];
		};

		return this.updateConversationAssignment(conv, "reject", onSuccess);
	},

	/**
	 *
	 * @param {*} conv
	 * @returns
	 */
	getDicoWithPath: function (header, path, onSuccess) {
		let data = {
			assistantID: header.assistantID,
			path
		};

		return dispatch => {
			dispatch(
				api({
					type: "cockpit/dico/getPath",
					url: "/api/v1/dico/getPath",
					method: "GET",
					data,
					onSuccess
				})
			);
		};
	},

	/**
	 *
	 * @param {*} header the conversation header
	 * @param {*} actionCode code of action target
	 * @param {*} onSuccess return result of dico and value of dico
	 * @returns
	 */
	getActionForm: function (data, onSuccess, onFailure) {
		return dispatch => {
			dispatch(
				api({
					type: "/action/getFormAction",
					url: `/api/v1/action/getFormAction`,
					method: "GET",
					data,
					onSuccess,
					onFailure
				})
			);
		};
	},

	/**
	 *
	 * @param {*} param0
	 * @returns
	 */
	sendAnswer: function ({
		answer,
		conv,
		CC,
		BCC,
		manualTopic,
		targetStates,
		urgent,
		message,
		conversationsAlreadyResolved,
		success
	}) {
		let storeState = store.getState();
		if (lod_.has(storeState.userStatus, "auth.user")) {
			let user = storeState.userStatus.auth.user;
			let ts = Date.now();
			let date = new Date();
			const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
			let d = isoDate;
			let d1 = d.substring(0, 10);
			let d2 = d.substring(11, 23);
			let datetime = d1 + " " + d2;

			let channel = conv.header.channel;
			if (channel === "FB" && conv.header.extra && conv.header.extra.FBsource) {
				conv.header.extra.FBsource = "messenger";
			}
			let attachments = storeState.cockpit.attachments;

			let data;

			if (conv.header.type === "ticket") {
				attachments = attachments?.map(attch => {
					// OLD FORMAT FILE
					if (attch?.file) {
						return {
							id: attch.id,
							name: attch.name,
							mimeType: attch.mimeType,
							size: attch.size,
							path: attch.file?.path || attch.path,
							url: attch.file?.url || attch.url,
							/**
							 * If attachment has path it means that it is an attachment
							 * from built in answer, so we need to copy it to bucket with a new URL
							 */
							needToCopyToBucket: Boolean(attch.needToCopyToBucket),
							/**
							 * Need type to download variable attachments
							 */
							type: attch.type
						};
						// NEW FORMAT FILE
					} else {
						return {
							...attch,
							/**
							 * If attachment has path it means that it is an attachment
							 * from built in answer, so we need to copy it to bucket with a new URL
							 */
							needToCopyToBucket: Boolean(attch.needToCopyToBucket)
						};
					}
				});
				data = {
					assistantID: conv?.header?.assistantID,
					fID: conv?.header?.fID,
					mID: message.mID,
					from: {
						id: user.email,
						name:
							user && user.surname
								? user.surname + " " + user.name
								: user.nickname + " " + user.name
					},
					to: {
						to: conv.contact.email,
						cc: CC,
						bcc: BCC
					},
					targetStates,
					message: {
						header: { ...message.message.header, urgent },
						payload: {
							...message.message.payload,
							subject: manualTopic || "",
							text: { html_text: answer },
							attachments
						}
					}
				};

				if (storeState?.engage?.contact) {
					// This is an engage and we have email info. We must link the conv to the contact + send message
					/**
					 * PIKA : THIS IS HIGHLY DANGEROUS SINCE THE VALUE OF storeState?.engage?.contact SEEMS NEVER BE RESETED
					 */
					data.to.engageLinkContact = storeState?.engage?.contact;
				}
			} else {
				data = {
					assistantID: conv?.header?.assistantID,
					fID: conv?.header?.fID,
					mID: message.mID,
					from: {
						id: user.email,
						name:
							user && user.surname
								? user.surname + " " + user.name
								: user.nickname + " " + user.name,
						url: user.picture?.url ? user.picture?.url : undefined
					},
					message: {
						header: { ...message.message.header, urgent },
						payload: {
							...message.message.payload,
							text: { plain_text: answer }
						}
					}
				};
				if (storeState?.engage?.contact) {
					// This is an engage and we have email info. We must link the conv to the contact + send message
					data.to = { engageLinkContact: storeState?.engage?.contact };
				}
				//type conversational/review
				if (attachments?.length) {
					const { file, name, size, mimeType, url } = attachments[0]; //why [0] ?
					attachments = [
						{
							type: "file",
							mimeType,
							url: url ? getACleanURI(url) : getACleanURI(file.url),
							name,
							contentDisposition: "attachment",
							size
						}
					];
					data.message.payload.attachments = attachments;
				}

				if (conv.header.type === "review") {
					// Specific case for review. When answering we want to set the state/substates values to resolved/resolved
					data.targetStates = {
						state: "resolved",
						substate: "resolved"
					};
				}
			}

			if (conv.header.extra.mqn) data.mqn = conv.header.extra.mqn;

			if (!lod_.isNil(conversationsAlreadyResolved))
				data.message.header.conversationsAlreadyResolved = conversationsAlreadyResolved;

			/**
			 * Maybe just send fID / mID to A-D and query the message in NYO ?
			 */
			return dispatch => {
				dispatch(
					api({
						type: "cockpit/conversation/sendAnswerr",
						url: "/api/v1/conversation/sendAnswer",
						method: "POST",
						data,
						onSuccess: res => {
							if (res?.checkNeed?.need === true) {
								success(res);
								return;
							}
							// CLEAN storeState?.engage?.contact;
							let actions = [
								{
									type: C.CLEAR_ATTACHMENTS
								},
								{
									type: C.CLEAR_CONTENT_MESSAGE
								},
								infoMsg(i18n.t("COC.Beingprocessed")),
								setSendEnabled(true),
								setSendSucceed(conv.header.fID)
							];
							if (conv.header.type === "ticket") {
								let payload = {
									assistantID: conv.header.assistantID,
									datetime: datetime,
									ts: ts,
									channel: conv.header.channel,
									extra: conv.header.extra, // Generating issue
									language: conv.header.language,
									name: user.nickname,
									uid: user.email,
									repliedAnswer: answer,
									sent_attachments: res,
									type: conv.header.type,
									state: targetStates.state,
									substate: targetStates.substate,
									topic: manualTopic,
									fID: conv.header.fID
								};
								payload.extra.emailCC = CC;
								payload.extra.emailBCC = BCC;
								if (storeState?.engage?.contact) {
									payload.engageLinkContact = storeState?.engage?.contact;
									// Reset the engage contact
									// NO WWAY !!!!!! DELETE THE VAR
									actions.push({
										type: C_engage.SET_ENGAGE_CONTACT,
										payload: ""
									});
								}
								actions.push({
									type: C.SEND_ANSWER,
									payload: payload
								});
							} else {
								actions.push({
									type: C.CONV_SEND_SUCCESS,
									payload: res
								});
							}

							// Make sure to reset the topic draft https://faibrikplatform.atlassian.net/browse/FBDB-1775
							actions.push({ type: C.SET_TOPIC_DRAFT, payload: "" });

							return actions;
						},
						onFailure: () => [errorMsg(i18n.t("COC.sendMessageFailed")), setSendEnabled(true)], // CLEAN storeState?.engage?.contact;
						onLoading: payload => ({
							type: C.CONV_SENDING,
							payload
						})
					})
				);
			};
		}
	},

	/**
	 *
	 * @param {*} text
	 * @param {*} conversation
	 * @returns
	 */
	postNote: function (dataNote, conversation, type) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;

			let ts = Date.now();
			let noteAuthor = user?._id;
			let note = { author: noteAuthor, type };

			if (type === "text" || type === "escalatedDone") {
				note.text = dataNote;
			} else if (type === "attachement") {
				note.attachement = dataNote;
			} else if (type === "escalated") {
				note.text = dataNote?.note;
				let escalationGroup = {
					_id: dataNote?.selectedGroup?._id,
					name: dataNote?.selectedGroup?.name,
					code: dataNote?.selectedGroup?.code || ""
				};
				note.escalationGroup = escalationGroup;
			}

			let data = {
				action: "addConvNote",
				data: {
					header: {
						assistantID: conversation.header.assistantID,
						fID: conversation.header.fID
					},
					payload: {
						note
					}
				},
				source: generalConstant.APP_NAME,
				ts
			};

			return dispatch => {
				axios(endPoint + "/api/v1/actionFront", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						dispatch(infoMsg(i18n.t("actionEvent.addedNote")));
					})
					.catch(error => {
						dispatch(errorMsg(i18n.t("actionEvent.addedNoteEchec")));
					});
			};
		}
	},

	/**
	 *
	 * @param {*} text
	 * @param {*} conversation
	 * @returns
	 */
	saveNote: function (text, conversation) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;

			/**
			 * TODO: - PIKA 2023.01.26 -> Terrible -> Il faut laisser NYO faire tous les calculs et juste envoyer le contenue de la note et la fID
			 */

			let ts = Date.now();
			let noteAuthor =
				user && user.surname ? user.surname + " " + user.name : user.nickname + " " + user.name;

			let note = {
				author: noteAuthor,
				text
			};

			let data = {
				action: "saveConvNote",
				data: {
					header: {
						assistantID: conversation.header.assistantID,
						fID: conversation.header.fID
					},
					payload: {
						note
					}
				},
				source: generalConstant.APP_NAME,
				ts
			};

			return dispatch => {
				axios(endPoint + "/api/v1/actionFront", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				});
			};
		}
	},
	deleteNote: function (noteInfos, conversation) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			const data = {
				action: "deleteConvNote",
				data: {
					header: {
						assistantID: conversation.header.assistantID,
						fID: conversation.header.fID
					},
					payload: {
						...noteInfos
					}
				},
				source: generalConstant.APP_NAME,
				ts: Date.now()
			};

			return dispatch => {
				axios(endPoint + "/api/v1/actionFront", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						dispatch(infoMsg(i18n.t("actionEvent.deletedNote")));
					})
					.catch(error => {
						dispatch(errorMsg(i18n.t("actionEvent.deletedNoteEchec")));
					});
			};
		}
	},
	escalateConversation: function (conversation, leftGroup, targetGroup, cb) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;

			let data = {
				email: user.email,
				name: user.name || user.surname || "",
				uid: user.uid,
				conversation: conversation,
				leftGroup: leftGroup,
				targetGroup: targetGroup,
				action: "escalate",
				header: conversation.header
			};
			return dispatch => {
				axios(endPoint + "/api/updateConversationEscalation", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						let isUserMemberOfEscalationGroup;
						if (store.getState().userGroup.groups)
							isUserMemberOfEscalationGroup = store
								.getState()
								.userStatus.auth.user.groups.some(group => targetGroup.includes(group));
						else isUserMemberOfEscalationGroup = false;

						let payload = {
							state: conversation.header.state,
							fID: conversation.header.fID,
							escalationGroup: targetGroup,
							leftGroup,
							isUserMemberOfEscalationGroup: isUserMemberOfEscalationGroup
						};
						dispatch({
							type: C.ESCALATE_CONVERSATION,
							payload: payload
						});

						//callback to show success popup
						cb();
					})
					.catch(error => {});
			};
		}
	},
	deescalateConversation: function (conversation, leftGroup, targetGroup, cb) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;

			let data = {
				email: user.email,
				name: user.name || user.surname || "",
				uid: user.uid,
				conversation: conversation,
				leftGroup: leftGroup,
				targetGroup: targetGroup,
				action: "deescalate",
				header: conversation.header
			};

			return dispatch => {
				axios(endPoint + "/api/updateConversationEscalation", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						let isUserMemberOfDeescalationGroup;
						if (store.getState().userGroup.groups)
							isUserMemberOfDeescalationGroup = store
								.getState()
								.userStatus.auth.user.groups.some(group => targetGroup.includes(group));
						else isUserMemberOfDeescalationGroup = false;

						let payload = {
							state: conversation.header.state,
							fID: conversation.header.fID,
							isUserMemberOfDeescalationGroup: isUserMemberOfDeescalationGroup
						};
						dispatch({
							type: C.DEESCALATE_CONVERSATION,
							payload: payload
						});

						//callback to show success popup
						cb();
					})
					.catch(error => {});
			};
		}
	},
	updateEscalationEvent: function (event) {
		return dispatch => {
			let isUserMemberOfTargetGroup;
			if (store.getState().userGroup.groups)
				isUserMemberOfTargetGroup = store
					.getState()
					.userStatus.auth.user.groups.some(group => event.targetGroup.includes(group));
			else isUserMemberOfTargetGroup = false;
			let payload = {
				conversation: event.content,
				isUserMemberOfTargetGroup: isUserMemberOfTargetGroup,
				user: store.getState().userStatus.auth.user
			};
			dispatch({
				type: C.UPDATE_ESCALATION_EVENT,
				payload: payload
			});
		};
	},
	redirectConversationEvent: function (event) {
		return dispatch => {
			let isUserMemberOfTargetGroup;
			if (store.getState().userGroup.groups)
				isUserMemberOfTargetGroup = store
					.getState()
					.userStatus.auth.user.groups.some(group => event.targetGroup.includes(group));
			else isUserMemberOfTargetGroup = false;
			let payload = {
				conversation: event.content,
				isUserMemberOfTargetGroup: isUserMemberOfTargetGroup,
				user: store.getState().userStatus.auth.user
			};
			dispatch({
				type: C.REDIRECT_CONVERSATION_EVENT,
				payload: payload
			});
		};
	},
	redirectConversationToUser: function (conv, user, cb) {
		let data = {
			action: "assignConv",
			data: {
				header: {
					assistantID: conv.header.assistantID,
					fID: conv.header.fID
				},
				payload: {
					to: {
						name: computeAgentName(user),
						first: user.name,
						last: user.surname,
						email: user._id
					}
				}
			},
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};

		const onSuccess = () => {
			let data = {
				convFID: conv.header.fID,
				role: store.getState().userStatus.auth.user.role,
				perms: store.getState().userStatus.auth.user.perms,
				user
			};
			//callback to show success popup

			cb();

			return {
				type: C.REDIRECT_TO_USER,
				payload: data
			};
		};

		return dispatch => {
			dispatch(
				api({
					type: "cockpit/redirectConversationToUser",
					url: "/api/v1/actionFront/",
					method: "POST",
					data,
					onSuccess
				})
			);
		};
	},
	redirectConversationToGroup: function (header, oldState, leftGroup, targetGroup, cb) {
		let fID = header.fID;

		let data = {
			action: "updateConvGroups",
			data: {
				header: {
					assistantID: header.assistantID,
					fID
				},
				payload: {
					resolverGroup: targetGroup
				}
			},
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};

		const onSuccess = () => {
			let isUserMemberOfRedirectionGroup;
			if (store.getState().userGroup.groups) {
				isUserMemberOfRedirectionGroup = store
					.getState()
					.userStatus.auth.user.groups.some(group => targetGroup.includes(group));
			} else {
				isUserMemberOfRedirectionGroup = false;
			}
			let payload = {
				convFID: fID,
				isUserMemberOfRedirectionGroup: isUserMemberOfRedirectionGroup,
				targetGroup: targetGroup,
				oldState
			};
			//callback to show success popup
			cb();
			return {
				type: C.REDIRECT_TO_GROUP,
				payload: payload
			};
		};

		return dispatch => {
			dispatch(
				api({
					type: "cockpit/redirectConversationToGroup",
					url: "/api/v1/actionFront",
					method: "POST",
					data: data,
					onSuccess
				})
			);
		};
	},
	uploadFile: (file, mimeType, id, fileName, fileSize, convID, assistantID, errorCallBack) => {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			return dispatch => {
				let data = new FormData();
				data.append("fileToUpload", file);
				const source = axios.CancelToken.source();
				dispatch({
					type: C.ADD_ATTACHMENT,
					payload: {
						name: fileName,
						mimeType: mimeType,
						id: id,
						source: source,
						size: fileSize,
						file: file
					}
				});

				axios
					.post(`${endPoint}/file/upload/${assistantID}&${convID}&${id}`, data, {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email
						},
						cancelToken: source.token,

						onUploadProgress: ProgressEvent => {
							let progress = Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100);
							dispatch({
								type: C.UPDATE_ATTACHMENT_PROGRESS,
								payload: {
									progress: progress,
									id: id
								}
							});
						}
					})
					.catch(e => {
						if (!axios.isCancel(e)) {
							errorCallBack(fileName);
							dispatch({
								type: C.REMOVE_ATTACHMENT_WHEN_ERROR,
								payload: {
									id: id
								}
							});
						}
					});
			};
		}
	},
	deleteFile: (id, fileName, convID, assistantID) => {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;

			return dispatch => {
				// attachments are not saved in tmp, do we need to keep this? 25/08/2022
				/* axios.delete(`${endPoint}/file/${id}&${assistantID}&${convID}&${fileName}`, {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					}
				}); */

				dispatch({
					type: "DELETE_ATTACHMENT",
					payload: {
						id
					}
				});
			};
		}
	},
	changeAscFilter: value => {
		return disptach => {
			disptach({
				type: C.SET_ASC_FILTER,
				payload: value
			});
		};
	},
	changeSupervisorFilter: (value, noBot = false) => {
		return disptach => {
			disptach([
				{
					type: C.SET_SUPERVISOR_FILTER,
					payload: { value, noBot }
				}
			]);
		};
	},
	changeAgentFilter: (value, noBot = false) => {
		return disptach => {
			disptach({
				type: C.SET_AGENT_FILTER,
				payload: { value, noBot }
			});
		};
	},
	convAgentFetchedOngoing: value => {
		return disptach => {
			disptach({
				type: C.SET_CONV_AGENT_FETCHED_ONGOING,
				payload: value
			});
		};
	},
	convAgentFetchedResolved: value => {
		return disptach => {
			disptach({
				type: C.SET_CONV_AGENT_FETCHED_RESOLVED,
				payload: value
			});
		};
	},
	getMID: data => {
		const onSuccess = payload => ({
			type: C.CONV_GET_MID,
			payload
		});

		return dispatch => {
			dispatch(
				api({
					type: "cockpit/getMID",
					url: "/api/v1/conversation/mID",
					data,
					onSuccess
				})
			);
		};
	},
	/**
	 *
	 * @param {*} conv
	 * @param {*} getPIvalues
	 * @returns
	 */
	selectConversation: (conv, getPIvalues, casePI = false, viewPI = false, newConv = true) => {
		return dispatch => {
			let storeState = store.getState();
			let isArchived = storeState.cockpit.leftTab === 0 && storeState.cockpit.isCockpitArchived;
			const onLoading = payload => ({
				type: C.CONV_LOADING,
				payload
			});

			const onSuccess = payload => ({
				type: C.SELECT_CONVERSATION,
				payload
			});

			return conv
				? dispatch(
						api({
							type: "cockpit/selectConversation",
							onLoading,
							url: "/api/v1/conversation",
							data: {
								assistantID: conv.header.assistantID,
								fID: conv.header.fID,
								isArchived,
								getPIvalues,
								casePI,
								viewPI,
								newConv
							},
							onSuccess
						})
					)
				: dispatch({
						type: C.CLEAR_CONVERSATION
					});
		};
	},
	EmptyHistory: () => {
		return dispatch => {
			dispatch({
				type: C.EMPTY_HISTORY,
				payload: []
			});
			dispatch({
				type: C.HISTORY_LOADING,
				payload: false
			});
		};
	},
	deleteConversation: convID => {
		return dispatch => {
			dispatch({
				type: C.DELETE_CONVERSATION,
				payload: convID
			});
		};
	},
	updateMessageStateEvent: event => {
		let currentAssistantID = store.getState().selectedAssistantID;

		if (currentAssistantID === event.assistantID) {
			return dispatch => {
				dispatch({
					type: C.UPDATE_MESSAGE_STATE_EVENT,
					payload: {
						convID: event.conversationID,
						convFID: event.conversationFID,
						msgIndex: event.msgIndex,
						state: event.msgState
					}
				});
			};
		}
	},
	setLeftTab: position => {
		return dispatch => {
			dispatch({
				type: C.SET_LEFT_TAB,
				payload: position
			});
		};
	},

	sendFeedback: (
		type,
		action,
		value,
		user,
		message,
		msgIndex,
		assistantID,
		fID,
		convID,
		agent,
		successCb,
		successMessage,
		failCb,
		failMessage,
		mID,
		selectedIntent,
		updateAlsoConvTopic
	) => {
		return dispatch => {
			let data = {
				action,
				feedback: {
					type,
					value,
					msgIndex,
					assistantID,
					agent,
					message,
					language: message.header.language,
					cleaned:
						message.payload?.payload?.text?.cleaned ||
						message.body?.cleaned ||
						message.payload?.payload?.text?.plain_text,
					fID,
					convID
				},
				fID,
				mID,
				selectedIntent,
				updateAlsoConvTopic
			};

			if (data.feedback.message.meta.feedback === undefined) {
				data.feedback.message.meta.feedback = {};
			}
			data.feedback.message.meta.feedback[type] = value;

			axios(endPoint + "/api/v1/sendFeedback", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: data
			})
				.then(result => {
					successCb({
						msg: successMessage,
						intent: { type: type, value: result.data.feedbackSaved.value }
					});
				})
				.catch(error => {
					failCb(failMessage);
				});
		};
	},

	renderMessage: (
		ansCode,
		ansUid,
		user,
		language,
		isRichAnswer,
		fromEngage = false,
		channel,
		ansContent,
		alternativeIndex,
		cb
	) => {
		return dispatch => {
			const selectedConversation = store.getState().cockpit.selectedConversation; //ALEX-> i introduced this for AMAZON CASE support

			let lastMeta = {};
			if (!fromEngage) {
				for (let i = 0; i < selectedConversation.messages.length; i++) {
					let message = selectedConversation.messages[selectedConversation.messages.length - i - 1];

					if (message.header.source === "contact") {
						lastMeta = message.meta;
						break;
					}
				}
			}

			let header;
			if (fromEngage) {
				header = {
					assistantID: store.getState().selectedAssistantID,
					channel: channel,
					language
				};
			}

			let data = {
				messageIn: {
					//TODO if fromEngage plutôt ?
					header: selectedConversation ? selectedConversation.header : header,
					ansCode,
					ansUid,
					// agent is for engage if we dont found conversation
					agent: {
						Client: "",
						isBot: false,
						name: user.name || user.surname || "",
						uid: user.email
					},
					alternativeIndex
				},
				lastMeta
			};

			data.messageIn.header.language = language;

			axios(endPoint + "/api/v1/renderMessage", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data
			})
				.then(result => {
					//ans.content.text = result.data.answer.content;
					if (result.data.answer.error) {
						cb(true, isRichAnswer, ansContent);
					} else {
						if (selectedConversation.header.type === "ticket") {
							ansContent.text = result.data.answer.content;
							ansContent.subject = result.data.answer.subject;
							ansContent.attachments = result.data.answer.attachments;
							cb(true, isRichAnswer, ansContent);
						} else {
							cb(true, isRichAnswer, result.data.answer.content);
						}
					}
				})
				.catch(error => {
					cb(false, isRichAnswer, ansContent);
				});
		};
	},

	saveDefaultChannel: (channel, openEngage) => {
		let payload = {
			channel,
			openEngage
		};
		return dispatch => {
			dispatch({
				type: C.SELECT_DEFAULT_CHANNEL,
				payload: payload
			});
		};
	},
	disableOpenEngage: value => {
		return dispatch => {
			dispatch({
				type: C.RESET_OPEN_ENGAGE,
				payload: value
			});
		};
	},

	updateTopic: function (assistantID, fID, intent, topicCategory) {
		let user = store.getState().userStatus.auth.user;
		let data = {
			action: "updateConvTopic",
			data: {
				header: {
					assistantID,
					fID
				},
				payload: {
					selectedIntent: intent,
					topicCategory
				}
			},
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};

		const onSuccess = () => {
			return [infoMsg(i18n.t("TabAction.updateTopic"))];
		};

		return dispatch => {
			dispatch(
				api({
					type: "cockpit/updateConvTopic",
					url: "/api/v1/actionFront",
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data,
					onSuccess
				})
			);
		};
	},
	updateConvPriority: function (assistantID, fID, selectedPriority) {
		let user = store.getState().userStatus.auth.user;
		let data = {
			action: "updateConvPriority",
			data: {
				header: {
					assistantID,
					fID
				},
				payload: {
					selectedPriority
				}
			},
			source: generalConstant.APP_NAME,
			ts: Date.now()
		};
		if (user) {
			return dispatch => {
				axios({
					url: endPoint + "/api/v1/actionFront",
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data
				});
			};
		}
	},
	launchActionFrontV2: function (action, data, nextActionsFrontNames, type = null) {
		let user = store.getState().userStatus.auth.user;
		const ts = Date.now();
		const source = generalConstant.APP_NAME;
		let postData = {
			action,
			data,
			type,
			source,
			ts
		};

		if (Array.isArray(nextActionsFrontNames)) {
			// In some case, we want to have other actions launched after the first one
			// for now we have the name and we need to generate the actionFormt for each
			postData.nextActionsFront = nextActionsFrontNames.map(actionObj => {
				// For all action to generate, create a format with no data to send
				// Could be improved with data in the future

				// actionObj can be:
				//		- string with action name only
				//		- object more info

				const clonedData = lod_.cloneDeep(data);

				if (typeof actionObj === "object") {
					clonedData.payload = actionObj.payload || {};

					return {
						action: actionObj.action,
						data: clonedData,
						source,
						ts
					};
				}
				if (typeof actionObj === "string") {
					clonedData.payload = {}; // Still keep clonedData.header part
					return {
						action: actionObj,
						data: clonedData,
						source,
						ts
					};
				}
			});
		}

		if (user) {
			return dispatch => {
				axios({
					url: endPoint + "/api/v1/actionFront",
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: postData
				})
					.then(result => {})
					.catch(error => {
						if (action === "updateConvContextPartial") {
							dispatch(errorMsg("Echec lors de la création d'affaire"));
						}
					});
			};
		}
	},
	setIsCockpitArchived: value => {
		return dispatch => {
			dispatch([
				{
					type: C.SET_IS_COLD,
					payload: false
				},
				/*	{
					type: C.CLEAR_CONVERSATION,
					payload: null
				},*/
				{
					type: C.SET_IS_COCKPIT_ARCHIVED,
					payload: value
				},
				{
					type: C.UPDATE_CONVERSATIONS,
					payload: []
				}
			]);
		};
	},

	setCopyClipboard: value => {
		let hasItem = !lod_.isEmpty(JSON.parse(localStorage.getItem("presse-papier")));

		return dispatch => {
			dispatch({
				type: C.SET_CLIPBOARD,
				payload: hasItem
			});
		};
	},

	updateConvMarketplace: function (fID, marketplace) {
		let data = {
			fID,
			marketplace
		};
		const onSuccess = payload => {
			return {
				type: ""
			};
		};
		return dispatch => {
			dispatch(
				api({
					type: "cockpit/updateConvMarketplace",
					url: "/api/v1/updateConvMarketplace",
					method: "POST",
					data,
					onSuccess
				})
			);
		};
	},

	callSetDefaultDraft: function (draftConent, draftTopic, header) {
		let data = { header };
		if (draftTopic !== "") {
			data = { ...data, draftTopic };
		}
		if (draftConent !== "") {
			data = { ...data, draftConent };
		}
		const onSuccess = res => {
			return res;
		};
		return dispatch =>
			dispatch(
				api({
					type: "cockpit/setDefaultDraft",
					url: "/api/v1/setDefaultDraft",
					method: "POST",
					data,
					onSuccess
				})
			);
	},
	callTranslateDraft: function (
		targetLang,
		topicContent,
		contentDraft,
		header,
		hasDefault,
		onSuccess = () => {},
		onFailure = () => {}
	) {
		let data = { header, target: targetLang, hasDefault };
		if (topicContent !== "") {
			data = { ...data, subject: topicContent };
		}
		if (contentDraft !== "") {
			data = { ...data, contentDraft };
		}
		return dispatch =>
			dispatch(
				api({
					type: "cockpit/translateDraft",
					url: "/api/v1/translateDraft",
					method: "POST",
					data,
					onSuccess,
					onFailure
				})
			);
	},
	callMessageAction: function (data, onSuccess = () => {}, onFailure = () => {}) {
		return dispatch =>
			dispatch(
				api({
					type: "cockpit/callMessageAction",
					url: "/api/v1/callMessageAction",
					method: "POST",
					data,
					onSuccess,
					onFailure
				})
			);
	},

	callMessageCategory: function (data, onSuccess = () => {}, onFailure = () => {}) {
		return dispatch =>
			dispatch(
				api({
					type: "cockpit/message/category",
					url: "/api/v1//message/category",
					method: "GET",
					data,
					onSuccess,
					onFailure
				})
			);
	},

	callFileAction: function (data) {
		let action = data.action;
		const onSuccess = () => {
			switch (action) {
				case "analyzeWaterMeter":
					return [infoMsg(i18n.t("TabAction.analyseSuccess"))];
				case "analyzeHeatPump":
					return [infoMsg(i18n.t("TabAction.analyseSuccess"))];

				default:
					return [infoMsg("File action complete")];
			}
		};

		return dispatch =>
			dispatch(
				api({
					type: "cockpit/callFileAction",
					url: "/api/v1/callFileAction",
					method: "POST",
					data,
					onSuccess
				})
			);
	},
	deleteDraft: function (conv, success) {
		return dispatch => {
			dispatch(
				api({
					type: "cockpit/deleteDraft",
					url: "/api/v1/deleteDraft",
					method: "POST",
					data: { fID: conv.header.fID },
					onSuccess: () => {
						if (success) success();
						return {
							type: C.DELETE_DRAFT,
							payload: { fID: conv.header.fID }
						};
					}
				})
			);
		};
	},
	clickToUpload: function (url, email, assistantID, mID, nameFile = "") {
		return dispatch => {
			dispatch(
				wsEvent({
					action: "clickToUpload",
					url: url,
					email: email,
					assistantID: assistantID,
					mID: mID,
					nameFile: nameFile
				})
			);
		};
	},
	computeDraft: function (assistantID, fID) {
		return dispatch => {
			dispatch(
				api({
					type: "cockpit/computeDraft",
					url: "/api/v1/computeDraft",
					method: "POST",
					data: { assistantID, fID },
					onSuccess: data => {
						const reducers = [];

						reducers.push({
							type: C.POST_DRAFT,
							payload: data?.response
						});

						// if (data?.response?.draftAnswer) {
						// 	reducers.push({
						// 		type: C.INSERT_HTML,
						// 		payload: data?.response?.draftAnswer
						// 	});
						// }
						if (data?.response?.draftTopic) {
							reducers.push({
								type: C.SET_TOPIC_DRAFT,
								payload: data?.response?.draftTopic
							});
						}
						return reducers;
					}
				})
			);
		};
	},
	changeFormatMessage: function (id, value) {
		return dispatch => {
			dispatch(
				api({
					type: "cockpit/changeFormatMessage",
					url: "/api/v1/changeFormatMessage",
					method: "PUT",
					data: { id, value }
				})
			);
		};
	}
};

export default actions;
